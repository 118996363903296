import React from "react";
import { graphql } from "gatsby";
import { markdownCleaner } from "../utils/markdownCleaner";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/seo";

const Page = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const page = data.contentfulPage;

    let content = "";
    if (page.morePageContent) {
        content = markdownCleaner(page.page_content.page_content + page.morePageContent.morePageContent);
    } else {
        content = markdownCleaner(page.page_content.page_content);
    }

    return (
        <Layout menus={menus} headerType={`page`} pageHeading={page.page_title}>
            <Seo title={page.page_title} slug={page.slug} bodyClass={page.slug} />
            <section className="st-section st-section--narrow" dangerouslySetInnerHTML={{ __html: content }} />
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query($slug: String!) {
        contentfulPage(slug: { eq: $slug }) {
            layout
            slug
            page_title
            page_content {
                page_content
            }
            morePageContent {
                morePageContent
            }
        }
    }
`;
